
import Vue from "vue";
import Router from "vue-router"
// import Home from "../components/Home.vue";

Vue.use(Router)

const router =  new Router({
    routes:[
        {
            path:'/',
            redirect:'/home',
            name:'home',
            // component : () => import('@/components/Home')  路由懒加载方式
            //component : resolve => require(['@/components/Home'],resolve)  异步组件加载方式
            component : () => import('@/pages/Home')
        },
        {
            path:'/home',
            name:'home',
            component : () => import('@/pages/Home')
        },
        {
            path:'/playMethod',
            name:'playMethod',
            component : resolve => require(['@/pages/PlayMethod'],resolve)
        },
        {
            path:'/CommonProblem',
            name:'CommonProblem',
            component : resolve => require(['@/pages/CommonProblem'],resolve)
        },
        {
            path:'/updateLogs',
            name:'updateLogs',
            component : resolve => require(['@/pages/UpdateLogs'],resolve)
        },
        // {
        //     path:'/pay',
        //     name:'pay',
        //     component : resolve => require(['@/pages/Pay'],resolve)
        // }
    ]
})

router.beforeEach((to, from, next) => {
    document.title = '開心門鬥地主';
    next();
});
export default router
