<template>
  <div id="app"> 
   <router-view></router-view>
  </div>
</template>

<script>
export default {
  mounted() {
    
  },
  created(){

  },
  methods:{

  }
}
</script>

<style>
@import url(./assets/css/reset.css);

@media screen and (min-width: 1000px) {
  body{
    background: none !important;;
  }
  .home {
    width:400px;
    margin: auto;
    background-image: url('./assets/image/bg.png');
    min-height: 100vh;
  }

  .van-tabbar--fixed{
    width: 400px!important;
    right: 0!important;
    margin: auto;
  }
}

</style>
