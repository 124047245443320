import Vue from 'vue'
import App from './App.vue'
import '../plugins/element.js'
import 'font-awesome/css/font-awesome.min.css'
import axios from 'axios'
import router from './router'
import VantGreen from 'vant-green';
import 'vant-green/lib/index.css';
import service from '../service.js'

import { Lazyload } from 'vant';

Vue.use(Lazyload);


Vue.use(VantGreen);

Vue.prototype.axios = axios  //挂载到原型，可以在全局使用
Vue.config.productionTip = false
Vue.prototype.service = service

// Vue.prototype.env = "local"; //本地环境
// Vue.prototype.env = "test";  //测试环境
Vue.prototype.env = "pro";      //生产环境

new Vue({
  router,
  render: h => h(App),
  beforeCreate() {
		Vue.prototype.$bus = this //安装全局事件总线
	},
}).$mount('#app')

//全局 引用element插件
// import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
// Vue.use(ElementUI);